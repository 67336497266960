<template>
  <div class="recruit_info_box">
    <moduleTitle title="JOIN US" />
    <ul class="info_list">
      <li v-for="item in recruitInfo" :key="item.id" :id="'info'+ item.id">
        <div class="content">
          <h4>{{$t(item.title)}}</h4>
          <!-- <div class="tabs">
            <span v-for="(tab,i) in item.tabsList" :key="i">{{tab}}</span>
          </div> -->
          <h5>投递邮箱：</h5>
          <p class="email">hrm@longwoodtech.com</p>
          <h5>岗位职责：</h5>
          <div class="duty_list">
            <p v-for="(duty,i) in item.dutyList" :key="i">{{duty}}</p>
          </div>
          <h5 v-if="item.requirementList.length>0">任职要求: </h5>
          <div class="duty_list">
            <p v-for="(requirement,i) in item.requirementList" :key="i">{{requirement}}</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import moduleTitle from '@/views/home/components/title.vue'
export default {
  components: {
    moduleTitle
  },
  data () {
    return {
      recruitInfo: [
        {
          id: 1,
          title: '招聘.临床研究总监',
          dutyList: [
            ' 1、负责公司创新药临床试验项目的计划、费用预算、开展和管理、协调等；',
            ' 2、负责研究中心的调研筛选、协议谈判，召开临床试验各阶段会议；',
            ' 3、负责临床研究所涉及外包CRO公司的调研、评估、筛选及合同商谈；',
            ' 4、掌握各中心临床进度，督促临床方案实施，解决临床试验过程中出现的问题，把控临床试验的质量；',
            ' 5、部门组织架构建设、考核及人员培训；',
            ' 6、协调和管理部门日常工作；',
            ' 7、参与公司创新药新项目临床部分的立项调研工作；',
            ' 8、负责公司创新药项目临床部分的申报资料和撰写工作。',
          ],
          requirementList: [
            ' 1、临床医学等相关医药专业，肿瘤、血管、眼科相关专业优先；',
            ' 2、熟悉化创新药各阶段临床研究的设计及执行；',
            ' 3、有制药企业或临床CRO公司五年以上创新药临床研究管理的相关经验，尤其是抗肿瘤血管、眼科药物临床研究经验；',
            ' 4、熟悉FDA、CFDA新药研发法律、法规、政策；',
            ' 5、熟悉国内、国际临床研究现状，有丰富行业资源；',
            ' 6、具有良好的协调沟通、团队管理和执行管理能力。',
            ' 7、优先考虑：眼科：DME（糖尿病黄斑水肿）／（DR）糖尿病视网膜病变／AMD（老年性黄斑变性）'
          ]
        },
        {
          id: 2,
          title: '招聘.项目经理',
          dutyList: [
            ' 1、全面负责公司创新药临床试验项目的管理工作，包括对项目进展、预算执行的监控；',
            ' 2、参与临床研究计划、临床研究方案的制订；',
            ' 3、组织开展临床试验中心、CRO公司等的筛选和评估工作；',
            ' 4、组织临床试验方案、原始病历、CRF、临床试验操作手册、实验室操作手册以及临床试验报告等相关技术文件的撰写、审阅和定稿工作；',
            ' 5、与研究者（PI）、临床试验机构、CRO及其他合作商建立良好的合作关系，做好沟通和协调工作，保证项目顺利实施；',
            ' 6、协调完成对临床试验项目的内部稽查和质量保证工作；',
            ' 7、参与公司各类项目申报和其他研发工作；',
            ' 8、完成公司安排的其他工作。'
          ],
          requirementList: [
            ' 1、具有医学、药学、生物学专业本科或硕士学历；',
            ' 2、五年以上临床试验项目的实施、管理的经验，具有国际CRO工作经验优先考虑；',
            ' 3、熟悉GCP、GLP操作规范和临床试验监查工作内容和流程；',
            ' 4、熟悉ICH指南、FDA、CFDA临床试验相关的各类法则和指导原则；',
            ' 5、具有良好的专业、公文书面写作能力；',
            ' 6、熟练的英语交流、阅读和写作能力；',
            ' 7、具有良好的时间管理及沟通、协调和问题解决能力；',
          ]
        },
        {
          id: 3,
          title: '招聘.高级临床监查员',
          dutyList: [
            ' 1、负责项目的进度与质量控制，保证试验数据的及时、规范和准确录入，确保资料数据的真实性、可靠性和完整性；',
            ' 2、参与编制临床试验监查管理制度和SOP文件，确保产品临床试验流程及文件符合国家临床研究规范GCP要求；',
            ' 3、监查并及时报告试验进度和质量、病例报告表填写情况、试验用药品使用情况及药品不良反应，保证试验资料的完整性、规范，并确保临床试验按计划完成，定期按照GCP要求提交监查报告；',
            ' 4、与临床医生沟通并共同协商解决出现的问题，协调研究项目负责人，临床医生、辅助科室、临床机构管理中心、患者等各方关系；',
            ' 5、对研究人员提供试验方案、GCP及CRF等的培训。'
          ],
          requirementList: [
            ' 1、临床医学、药学、护理学等相关专业，本科及以上学历；',
            ' 2、掌握临床试验管理规范的相关知识；',
            ' 3、熟悉GCP法规，有临床监查工作经验者优先；',
            ' 4、具有出色的沟通协调能力与抗压能力，接受经常出差；',
            ' 5、具有较强的分析问题的能力、语言表达能力，良好的人际交往能力，工作积极并具有团队合作精神；',
            ' 6、熟练的中英文阅读能力，熟练使用办公软件。'
          ]
        },
        {
          id: 4,
          title: '招聘.项目专员',
          dutyList: [
            ' 1、负责公司内外部项目的跟进，每月组织项目会，汇总项目进度，整理项目报告；',
            ' 2、协助完成公司研发项目预算管理；',
            ' 3、负责研发部门日常事务的管理、跨部门协调和联络；',
            ' 4、文件的的起草、修改、审核及整理工作；',
            ' 5、其他交代的任务。'
          ],
          requirementList: [
            ' 1、本科及以上，药学、分子生物学、化学、医学专业优先；',
            ' 2、相关工作经验2年以上；',
            ' 3、熟练使用word、EXCEL等办公软件；',
            ' 4、具备较强沟通协调能力和执行能力。'
          ]
        },
        {
          id: 5,
          title: '招聘.注册经理',
          dutyList: [
            ' 1、负责药品注册申报、跟进、协调、注册文件的管理；',
            ' 2、按照国家规定准备注册资料，协调研发、生产、质量等各个部门推动所注册产品在预定的时 间内拿到注册批文；',
            ' 3、充分利用对国家药品的相关政策法规和技术要求的熟悉以及药品注册的管理规定的掌握，规 划并整合资源，搜集相关资料，提升注册效率；',
            ' 4、有效跟踪国家新药政策的变化并及时反馈至公司，适时调整相应工作；',
            ' 5、维护与各级药监相关部门的关系，推进产品注册流程，协助与注册相关的其他工作；',
            ' 6、负责政府申报相关事务；',
            ' 7、完成主管上级和公司交办的其它工作。'
          ],
          requirementList: [
            ' 1、本科及以上学历，五年以上医药行业注册工作经验，能独立查阅有关文献资料，撰写和审核各类报告，具有肿瘤、血管、眼科创新药注册经验者优先；',
            ' 2、熟悉药品注册法律法规及药物的指导原则，熟悉药品注册申报程序及各类药物注册申报材料的相关要求，具有丰富的药品注册经验和管理经验；',
            ' 3、优秀的沟通协调能力；',
            ' 4、具有清晰地书面和口头表达能力，良好的公关能力，善于进行活跃而积极地沟通；',
            ' 5、具有独立工作能力，但同时又具有较强的集体意识和良好的团队合作精神；',
            ' 6、具有优秀的问题解决能力。',
          ]
        }
      ]
    }
  },
  methods: {

  },
  mounted () {
    const id = this.$route.query.id
    if (id) {
      this.$nextTick(() => {
        document.querySelector('#info' + id).scrollIntoView({
          block: 'end',
          behavior: 'smooth'
        })
      })

    }
  },
}
</script>
<style lang="less" scoped>
.recruit_info_box {
  width: 100%;
  min-height: calc(100vh - 76px);
  padding-top: 20px;
  .info_list {
    width: 100%;
    li {
      padding: 40px 0px;
      &:nth-child(odd) {
        background-color: #fff;
      }
      &:nth-child(even) {
        background-color: #eaecef;
      }
      .content {
        width: var(--width);
        padding: 0 60px;
        margin: 0 auto;
      }
      h4 {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 10px;
      }
      .tabs {
        font-size: 14px;
        color: #666;
        margin-bottom: 15px;
        span {
          padding: 0 10px;
          border-right: 1px solid #666666;
          &:last-child {
            border: 0px;
          }
        }
      }
      h5 {
        font-size: 14px;
        font-weight: 700;
        color: #595959;
        margin-bottom: 10px;
      }
      .email {
        font-size: 12px;
        font-weight: 400;
        color: #254289;
        margin-bottom: 14px;
      }
      .duty_list {
        padding: 0 20px;
        margin-bottom: 14px;
        p {
          list-style-type: decimal;
          font-size: 12px;
          color: #595959;
          padding: 5px 0;
          line-height: 17px;
        }
      }
    }
  }
}
</style>